import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEducationDepartaments = () => {
  return ApiService.get("education_departments");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getEducationDepartament = (id) => {
  return ApiService.get("education_departments", id);
};

/**
 * POST request to create a new education_departament
 * @param payload
 * @returns {*}
 */
export const createEducationDepartament = (payload) => {
  return ApiService.post("education_departments", payload);
};

/**
 * PUT request to update a specific education_departament
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateEducationDepartament = (id, payload) => {
  return ApiService.update("education_departments", id, payload);
};

/**
 * DELETE request to delete the specified education_departament
 * @param id
 * @returns {*}
 */
export const deleteEducationDepartament = (id) => {
  return ApiService.delete(`education_departments/${id}`);
};

/**
 * GET request to change status of the specified education_departament
 * @param id
 * @returns {*}
 */
export const changeStatusEducationDepartament = (id) => {
  return ApiService.get("education_departments/status", id);
};

export default {
  getAllEducationDepartaments,
  getEducationDepartament,
  createEducationDepartament,
  updateEducationDepartament,
  deleteEducationDepartament,
  changeStatusEducationDepartament,
};
